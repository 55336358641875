import React from "react";
import Image from "next/image";

function FeaturesZigzag() {
	return (
		<section>
			<div className="max-w-6xl mx-auto px-4 sm:px-6">
				<div className="py-12 md:py-20 border-t border-gray-800">
					{/* Section header */}
					<div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
						<div className="inline-flex text-sm font-semibold py-1 px-3 m-2 text-green-600 bg-green-200 rounded-full mb-4">
							Smarter support, better service.
						</div>
						<h2 className="h2 mb-4">
							Powerful AI features at your support team&apos;s fingertips.
						</h2>
						<p className="text-xl text-gray-400">
							SuperSupport.ai offers a range of powerful AI features that enable
							your support team to handle tickets more efficiently.
						</p>
					</div>

					{/* Items */}
					<div className="grid gap-20">
						{/* 1st item */}
						<div className="md:grid md:grid-cols-12 md:gap-6 items-center">
							{/* Image */}
							<div
								className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 aos-init aos-animate"
								data-aos="fade-up"
							>
								<Image
									className="max-w-full mx-auto md:max-w-none h-auto"
									width="540"
									height="405"
									src="/static/images/screenshot-3.png"
									alt="Rewrite messages with ease"
								/>
							</div>
							{/* Content */}
							<div
								className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 aos-init aos-animate"
								data-aos="fade-right"
							>
								<div className="md:pr-4 lg:pr-12 xl:pr-16">
									<div className="font-architects-daughter text-xl text-purple-600 mb-2">
										More tickets. Less time.
									</div>
									<h3 className="h3 mb-3">Rewrite Messages with Ease</h3>
									<p className="text-xl text-gray-400 mb-4">
										SuperSupport.ai&apos;s message rewriting feature can quickly
										and easily transform messages to sound more professional or
										friendly.
									</p>
									<ul className="text-lg text-gray-400 -mb-2">
										<li className="flex items-center mb-2">
											<svg
												className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
												viewBox="0 0 12 12"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
											</svg>
											<span>
												Improve the quality of your support interactions
											</span>
										</li>
										<li className="flex items-center mb-2">
											<svg
												className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
												viewBox="0 0 12 12"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
											</svg>
											<span>Save time by avoiding rewrites from scratch</span>
										</li>
										<li className="flex items-center">
											<svg
												className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
												viewBox="0 0 12 12"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
											</svg>
											<span>Ensure consistent messaging across your team</span>
										</li>
									</ul>
								</div>
							</div>
						</div>

						{/* 2nd item */}
						<div className="md:grid md:grid-cols-12 md:gap-6 items-center">
							{/* Image */}
							<div
								className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 rtl aos-init aos-animate"
								data-aos="fade-up"
							>
								<Image
									className="max-w-full mx-auto md:max-w-none h-auto"
									src={"/static/images/escalation.png"}
									width="540"
									height="405"
									alt="Summarize tickets in seconds"
								/>
							</div>
							{/* Content */}
							<div
								className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 aos-init aos-animate"
								data-aos="fade-left"
							>
								<div className="md:pl-4 lg:pl-12 xl:pl-16">
									<div className="font-architects-daughter text-xl text-purple-600 mb-2">
										Support smarter, not harder.
									</div>
									<h3 className="h3 mb-3">Summarize Tickets in Seconds</h3>
									<p className="text-xl text-gray-400 mb-4">
										SuperSupport.ai&apos;s summarize feature uses advanced AI
										technology to extract key information from entire ticket
										threads in seconds.
									</p>
									<ul className="text-lg text-gray-400 -mb-2">
										<li className="flex items-center mb-2">
											<svg
												className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
												viewBox="0 0 12 12"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
											</svg>
											<span>Save time and streamline your workflow</span>
										</li>
										<li className="flex items-center mb-2">
											<svg
												className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
												viewBox="0 0 12 12"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
											</svg>
											<span>
												Simplify the escalation process with easy-to-digest
												summaries
											</span>
										</li>
										<li className="flex items-center">
											<svg
												className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
												viewBox="0 0 12 12"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
											</svg>
											<span>
												Provide faster, more effective resolutions for your
												customers
											</span>
										</li>
									</ul>
								</div>
							</div>
						</div>

						{/* 3rd item */}
						<div className="md:grid md:grid-cols-12 md:gap-6 items-center">
							{/* Image */}
							<div
								className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 aos-init aos-animate"
								data-aos="fade-up"
							>
								<p className="font-extrabold text-center h2 text-transparent bg-clip-text bg-gradient-to-r to-white from-indigo-500">
									Coming soon!
								</p>
								{/* <img
									className="max-w-xs mx-0 mx-auto md:max-w-none h-auto"
									src={FeatImage03}
									width="540"
									height="405"
									alt="Features 03"
								/> */}
							</div>
							{/* Content */}
							<div
								className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 aos-init aos-animate"
								data-aos="fade-right"
							>
								<div className="md:pr-4 lg:pr-12 xl:pr-16">
									<div className="font-architects-daughter text-xl text-purple-600 mb-2">
										Faster support, happier customers.
									</div>
									<h3 className="h3 mb-3">
										Suggested responses, trained on your tickets.
									</h3>
									<p className="text-xl text-gray-400 mb-4">
										SuperSupport.ai&apos;s suggested responses feature uses AI
										technology to analyze a customer&apos;s ticket history in
										Zendesk and suggest responses that best fit their needs.
									</p>
									<ul className="text-lg text-gray-400 -mb-2">
										<li className="flex items-center mb-2">
											<svg
												className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
												viewBox="0 0 12 12"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
											</svg>
											<span>
												Save time by having personalized responses at your
												fingertips
											</span>
										</li>
										<li className="flex items-center mb-2">
											<svg
												className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
												viewBox="0 0 12 12"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
											</svg>
											<span>
												Improve customer satisfaction with tailored support
											</span>
										</li>
										<li className="flex items-center">
											<svg
												className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
												viewBox="0 0 12 12"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
											</svg>
											<span>
												Streamline your support process with efficient responses
											</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default FeaturesZigzag;
