import Script from "next/script";

import Header from "./Header";
import Footer from "../components/Footer";
import Head from "next/head";
import AOS from "aos";
import { useEffect } from "react";

function Layout({ children }) {
	useEffect(() => {
		AOS.init({
			once: true,
			// disable: "phone",
			duration: 600,
			easing: "ease-out-sine",
		});
	});

	return (
		<>
			<Head>
				<link rel="stylesheet" href="https://unpkg.com/aos@next/dist/aos.css" />
				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href="/static/apple-touch-icon.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="32x32"
					href="/static/favicon-32x32.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href="/static/favicon-16x16.png"
				/>
				<link rel="manifest" href="/static/site.webmanifest" />
				<link
					rel="mask-icon"
					href="/static/safari-pinned-tab.svg"
					color="#5bbad5"
				/>
				<meta name="msapplication-TileColor" content="#da532c" />
				<meta name="theme-color" content="#ffffff" />
			</Head>
			<Script
				defer
				data-domain="supersupport.ai"
				src="/events/js/script.outbound-links.js"
				data-api="/events/api/event"
			/>
			<div className="font-inter antialiased bg-gray-900 text-gray-200 tracking-tight dark">
				<div className="flex flex-col min-h-screen overflow-hidden">
					{/*  Site header */}
					<Header />

					{/*  Page content */}
					<main className="grow">{children}</main>

					{/*  Site footer */}
					<Footer />
				</div>
			</div>
		</>
	);
}

export default Layout;
