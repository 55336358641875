import React from "react";

import PageIllustration from "../components/PageIllustration";
import HeroHome from "../components/HeroHome";
import FeaturesZigZag from "../components/FeaturesZigzag";
import Newsletter from "../components/Newsletter";
import Layout from "../components/Layout";
import { NextSeo } from "next-seo";
import Pricing from "../components/Pricing";

function Home() {
	return (
		<Layout>
			<NextSeo
				title="AI superpowers for your support team"
				canonical={`https://supersupport.ai/`}
			/>
			{/*  Page content */}
			<main className="grow">
				{/*  Page illustration */}
				<div
					className="relative max-w-6xl mx-auto h-0 pointer-events-none"
					aria-hidden="true"
				>
					<PageIllustration />
				</div>

				{/*  Page sections */}
				<HeroHome />
				{/* <FeaturesBlocks /> */}
				<FeaturesZigZag />
				{/* <Testimonials /> */}
				{/* <Newsletter /> */}
				<Pricing />
			</main>
		</Layout>
	);
}

export default Home;
